
<template>
 
   <div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="my_data==null">
      <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n titleh2n_news">
                   <Skeleton />
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 div-content-image-li">
                  <div class="home-carousel owl-theme owl-carousel">
                    <div class="slide-item1">
                      <div class="image-layer1">
                        <a href="#" title="#">
                           <Skeleton />
                        </a>
                      </div>

                    </div>
                   
                  </div>
                </div>
              </div>
          </div>
          
      </SkeletonTheme>
    </div>


    <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else>
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n titleh2n_news">
                   {{my_data.name}}
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
              <div class="row">
               
             
                    <div class="col-6 mb-4  ">
                      <div class="image-layer-view border">
                        <a rel="gallery" class="fancybox" :href="myurl+''+my_data.picture" :title="my_data.name">
                          <img class="resize-image lazy p-1 img-thumbnails" :src="myurl+''+my_data.picture" :alt="my_data.name" />
                        </a>
                      </div>

                    </div>
                    <div  class="col-6 mb-4 " v-for="(v,k) in multiPicture" :key="k">
                      <div class="image-layer-view border">
                        <a  rel="gallery"  class="fancybox" :href="myurl+''+v"  :title="my_data.name">
                          <img  class="resize-image  lazy p-1 img-thumbnails" :src="myurl+''+v" :alt="my_data.name" />
                        </a>
                      </div>
                    </div>
                    

                
              </div>
          </div>
          
             <TopNews  :Header="2"></TopNews>
        </div>

</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import TopNews from "@/components/default/TopNews.vue";
  import axios from 'axios';
export default {
  
 
  data() {
    return {
      

      myurl:window.my_url,
      my_data:null,
      mycate:null,
      multiPicture:[],

      page: 1,

      myscript:[]
    };
  },

  components: {
    Skeleton,
    SkeletonTheme,
    TopNews
   
    
  },
  created(){
        let p1=    axios.get(window.my_api+"api/album/get-album-by-slug?slug="+this.$route.params.slug,
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
              .then( (res)=>{
                  
                  if(res.status==200){

                       this.my_data=res.data.data;
                      this.multiPicture=this.my_data.multiPicture.split(",");          
                     // console.log(this.multiPicture);            
                  }else {
                    console.log("ERROR",res);
                  }
                   

                });
              

              
           Promise.all([p1]).then(()=>{
              this.myscript=[

                     {
                        src:"/layout/jquery.js",
                        type:"text/javascript"
                      },
                      {
                      src:"/layout/admin/jquery.fancybox/fancybox/jquery.fancybox-1.3.4.js",
                      type:"text/javascript",
                      body:true,
                      async:true,
                    },
                     {
                      src:"/layout/admin/js/main.js",
                      type:"text/javascript",
                       body:true,
                       async:true,
                    
                    }
              ];     
          });

      
  },
  methods:{
   
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    changePage(e) {
      this.page = e;
    }
  },
 
   metaInfo(){
    return {
     

        
        script:this.myscript
        
      }
  }

}
</script>
